<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="LBLREV" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiDocumentId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVNO"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="LBLREVPERIOD"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="LBLREVUSER"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVREASON"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 기본정보 -->
          <c-card :title="popupParam.title + $label('LBLBASEINFO')" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiDocumentId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="LBL0010565"
                itemText="revisionNum"
                itemValue="psiDocumentId"
                name="selectedpsiDocumentId"
                label=""
                v-model="selectedpsiDocumentId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 개정 -->
                <c-btn
                  v-show="editable && popupParam.psiDocumentId && !isRevision"
                  label="LBLREV"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="editable && popupParam.psiDocumentId && isRevision"
                  label="LBLREVCANCEL"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!-- 삭제 -->
                <c-btn
                  v-if="editable && popupParam.psiDocumentId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <!-- 저장 -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <!-- 문서일련번호 -->
                <c-text
                  :editable="false"
                  label="LBL0010462"
                  name="psiDocumentId"
                  v-model="data.psiDocumentId"
                />
              </div>
              <div class="col-6">
                <!-- 문서명 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010463"
                  name="documentTitle"
                  v-model="data.documentTitle"
                />
              </div>
              <div class="col-12">
                <!-- 문서내용 -->
                <c-textarea
                  :editable="editable"
                  label="LBL0010464"
                  name="documentContents"
                  v-model="data.documentContents"
                  :rows="3"
                />
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <!-- 관련공정 -->
                <c-process
                  :editable="editable"
                  label="LBL0001705"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <!-- 관리부서 -->
                <c-dept
                  :editable="editable"
                  label="LBL0001721"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
              <div class="col-3">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 20px !important;">
          <!-- 첨부파일 -->
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="LBLATTACHMENT">
          </c-upload>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'pid-master-detail',

  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
          psiDocumentId: '',
          documentClassCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PSI_DOC',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      selectedpsiDocumentId: null,
      saveUrl: transactionConfig.psi.document.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        documentClassCd: '',  // 문서분류 일련번호
        psiDocumentId: '',  // 문서 일련번호
        documentNo: '',  // 문서번호
        plantCd: '',  // 사업장코드
        deptCd: '',  // 관리부서
        processCd: '',  // 공정코드
        documentTitle: '',  // 문서명
        documentContents: '',  // 문서내용
        useFlag: 'Y',  // 사용여부
        deleteFlag: 'N',  // 삭제여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.document.get.url;
      this.insertUrl = transactionConfig.psi.document.insert.url;
      this.updateUrl = transactionConfig.psi.document.update.url;
      this.deleteUrl = transactionConfig.psi.document.delete.url;
      this.revlistUrl = selectConfig.psi.document.list.url + '/revs';

      this.getData();
    },
    getData() {
      this.$set(this.data, 'documentClassCd', this.popupParam.documentClassCd)
      this.isRevision = false;
      if (this.popupParam.psiDocumentId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.psiDocumentId)
        this.getDetail(this.popupParam.psiDocumentId, true);
        this.selectedpsiDocumentId = this.popupParam.psiDocumentId
      }
    },
    getDetail(_psiDocumentId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiDocumentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiDocumentId == this.popupParam.psiDocumentId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiDocumentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.documentClassCd = this.popupParam.documentClassCd;
              this.data.deleteFlag = 'N';
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiDocumentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiDocumentId = result.data.psiDocumentId
      this.saveCallData = uid();
      this.$set(this.attachInfo, 'taskKey', result.data.psiDocumentId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.psiDocumentId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.psiDocumentId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.data.psiDocumentId) {
          this.selectedpsiDocumentId = row;
          this.getDetail(this.selectedpsiDocumentId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedpsiDocumentId)
        }
      }
    },
  }
};
</script>